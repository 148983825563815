<template>
<v-app>
    <v-main>
        <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app>
            <v-list>

                <v-list-item>
                    <v-list-item-avatar>
                        <v-img :src="usuarioGlobal.imagen"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="title" v-text="usuarioGlobal.apellidosNombres"></v-list-item-title>
                        <v-list-item-subtitle v-text="usuarioGlobal.usuario"></v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>

            <v-list dense>
                <v-list-item v-model="estadoMenuInicioActivo" :to="{name:'inicioPanel'}">
                    <v-list-item-icon>
                        <v-icon>mdi-home</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Inicio</v-list-item-title>
                </v-list-item>

                <v-list-group prepend-icon="mdi-lock" no-action v-model="estadomenuSeguridad">
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title>Seguridad</v-list-item-title>
                        </v-list-item-content>
                    </template>

                    <v-list-item v-model="estadomenuRoles">
                        <v-list-item-title>Roles</v-list-item-title>
                        <v-list-item-icon>
                            <v-icon>mdi-shield</v-icon>
                        </v-list-item-icon>
                    </v-list-item>


                    <v-list-item v-model="estadomenuUsuarios" :to="{name:'usuariosPanel'}">
                        <v-list-item-title>Usuarios</v-list-item-title>
                        <v-list-item-icon>
                            <v-icon>mdi-account</v-icon>
                        </v-list-item-icon>
                    </v-list-item>

                </v-list-group>

                <v-list-item @click="cerrarSesion()" :disabled="btnCerrarSesion">
                    <v-list-item-action>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Cerrar sesión</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

        </v-navigation-drawer>

        <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title class="ml-0 pl-4">
                <span class="hidden-sm-and-down" v-text="empresa.nombre"></span>
            </v-toolbar-title>
            <!--<v-text-field
            flat
            solo-inverted
            hide-details
            prepend-inner-icon="mdi-magnify"
            label="Search"
            class="hidden-sm-and-down"
          ></v-text-field>-->
            <v-spacer></v-spacer>

            <v-switch @change="cambiarThema" class="mt-5 mr-10" v-model="modoOscuro" label="Modo oscuro"></v-switch>

            <v-menu :nudge-width="200">

                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-badge color="error" content="2" overlap="" left="">
                            <v-icon>mdi-bell</v-icon>
                        </v-badge>
                    </v-btn>
                </template>

                <v-card>
                    <v-list>
                        <v-list-item link>

                            <v-list-item-icon>
                                <v-icon>mdi-bell-ring</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>John Leider</v-list-item-title>
                                <v-list-item-subtitle>Founder of Vuetify.js</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider></v-divider>

                        <v-list-item link>

                            <v-list-item-icon>
                                <v-icon>mdi-bell-ring</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>John Leider</v-list-item-title>
                                <v-list-item-subtitle>Founder of Vuetify.js</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                    </v-list>

                </v-card>

            </v-menu>

            <v-menu :nudge-width="200">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon large v-bind="attrs" v-on="on">
                        <v-avatar size="32px" item>
                            <v-img :src="usuarioGlobal.imagen"></v-img>
                        </v-avatar>
                    </v-btn>
                </template>

                <v-card>
                    <v-list>
                        <v-list-item>
                            <v-list-item-avatar>
                                <v-img :src="usuarioGlobal.imagen"></v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title v-text=" usuarioGlobal.apellidosNombres ? usuarioGlobal.apellidosNombres : '' "></v-list-item-title>
                                <v-list-item-subtitle v-text=" usuarioGlobal.usuario ? usuarioGlobal.usuario : '' "></v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn href="#" color="info" block="">
                            <v-icon right="">mdi-account</v-icon>
                            Mi perfil
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn href="#" color="primary" block="">
                            <v-icon right="">mdi-shield-home</v-icon>
                            Cambiar Sucursal
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn :disabled="btnCerrarSesion"  color="error" block="" @click="cerrarSesion">
                            <v-icon right="">mdi-logout</v-icon>
                            Cerrar Sesión
                        </v-btn>
                    </v-card-actions>
                </v-card>

            </v-menu>

        </v-app-bar>

        <router-view></router-view>

        <div style="margin-top:50px">
            <v-footer absolute="" padless="" class="font-weight-medium">
                <v-col class="text-center" cols="12">
                    {{ new Date().getFullYear() }} — <strong v-text="empresa.nombre"></strong>
                </v-col>
            </v-footer>
        </div>
    </v-main>
</v-app>
</template>

<script>
export default {

    data() {
        return {
            usuarioGlobal: {
                usuario: '',
                apellidosNombres: '',
                imagen: require('../../assets/user_no_photo.png'),
            },

            empresa: {
                nombre: 'Optimania',
            },
            modoOscuro: false,
            drawer: true,
            estadoMenuInicioActivo: false,
            estadomenuConfiguracion: false,
            estadomenuEmpresa: false,
            estadomenuSucursal: false,
            estadomenuSeguridad: false,
            estadomenuRoles: false,
            estadomenuUsuarios: false,
            estadomenuAlmacen: false,
            estadomenuCategorias: false,
            estadomenuMarcas: false,
            estadomenuProductos: false,
            estadomenuCodigoBarras: false,
            estadomenuCompras: false,
            estadomenuProveedores: false,
            estadomenuIngresos: false,
            estadomenuVentas: false,
            estadomenuClientes: false,
            estadomenuVenta: false,
            btnCerrarSesion: false
        }
    },

    methods: {

        datosUsuario(){
            let usuario = this.$session.get('usuario');
            this.usuarioGlobal.usuario = usuario.correo;
            this.usuarioGlobal.apellidosNombres = 'JONNY PONCE AVILEZ';
        },

        cerrarSesion() {
            this.btnCerrarSesion = true;

            this.axios.post('api/panel/login/cerrarSesion')
            .then(res => {
                console.log(res.data);
                this.$session.clear();
                this.$router.push({name:'loginPanel'});
            })
            .catch(err => {
                this.btnCerrarSesion = false;
                console.error(err.response); 
            })

        },

        cambiarThema() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            this.$session.set("usarModoOscuro", this.$vuetify.theme.dark.toString())
        }

    },

    mounted() {
        const theme = this.$session.get("usarModoOscuro");
        if (theme) {
            if (theme === "true") {
                this.$vuetify.theme.dark = true;
                this.modoOscuro = true;
            } else {
                this.$vuetify.theme.dark = false;
                this.modoOscuro = false;
            }
        }
    },

    created() {
        this.datosUsuario();
    },

}
</script>
